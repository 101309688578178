import { th } from 'date-fns/locale';
import * as PIXI from 'pixi.js';
import { Subject } from 'rxjs';
import { Point, Size } from '../models';
import { AnchorPosition } from './const';
import { info } from 'console';
export enum RobAPlayerNumbersState {
    idle, stopping, done
}

export interface RobAPlayerPlayer {
    IdPlayer: number;
    Affected: boolean;
    InvolvedInSpellExecution: boolean;
    UsedImmunity: boolean;
    Name: string;
    Chips: number;
    AvatarURL: string;
    PlayerSpells: any;

    avatar?: string;
    robbedAmount?: number;
}
export class RobAPlayer {
    public container: PIXI.Container;
    private onShowDone = new Subject<any>();
    onShowDoneObservable$ = this.onShowDone.asObservable();

    private containerSize: Size = { width: 960, height: 960 };
    private logoPosition: Point = { x: 480, y: 262 };
    private raysSprite: PIXI.Sprite;
    private numbersSprite: PIXI.Sprite;
    private slotMachineSprite: PIXI.Sprite;
    private logoSprite: PIXI.Sprite;

    private animTextures: PIXI.Texture[] = [];
    private animIndex = 0;
    private animTimer = 0;

    private minNumYPos = 0;
    private maxNumYPos = 0;

    private maskSize: Size = { width: 490, height: 136 };
    private maskPos: Point = { x: 480, y: 539 };
    private numberMask: PIXI.Graphics;

    private logoScale = 1;
    private dScale = 0.003;

    private targetPosition;
    private numbersTempTimer = 0;
    private numbersState: RobAPlayerNumbersState = RobAPlayerNumbersState.idle;
    private targetLength;
    private currentLength = 0;


    private multipliersContainer: PIXI.Container;
    private textures;

    private blurFilter = new PIXI.filters.BlurFilter()
    private affectedPlayerChips: PIXI.Text
    private affectedPlayer: RobAPlayerPlayer
    private affectedPlayerChipsMinusAmount: number;
    private affectedPlayerStartChips: number;


    private initiatorPlayerChips: PIXI.Text
    private initiatorPlayer: RobAPlayerPlayer
    private initiatorPlayerStartChips: number;


    private arrowUp: PIXI.Sprite;
    private arrowDown: PIXI.Sprite;
    private arrowAnimatioZoomDirection = false;


    private infoText: PIXI.Text
    private infoTextBackground: PIXI.Graphics
    constructor(textures) {
        this.textures = textures;
        this.container = new PIXI.Container();

        this.multipliersContainer = new PIXI.Container();

        this.raysSprite = new PIXI.Sprite(textures['robAPlayerSpinRays'].texture);
        this.raysSprite.anchor.set(0.5);
        this.raysSprite.position.set(this.containerSize.width / 2, this.containerSize.height / 2);
        this.container.addChild(this.raysSprite);




        this.minNumYPos = -45;
        this.container.addChild(this.multipliersContainer)

        this.animTextures.push(textures['robAPlayerSpinSlotFrame1'].texture);
        this.animTextures.push(textures['robAPlayerSpinSlotFrame2'].texture);
        this.animTextures.push(textures['robAPlayerSpinSlotOn'].texture);
        this.animTextures.push(textures['robAPlayerSpinSlotOff'].texture);

        this.slotMachineSprite = new PIXI.Sprite(this.animTextures[0]);
        this.slotMachineSprite.anchor.set(0.5);
        this.slotMachineSprite.position.set(this.containerSize.width / 2, this.containerSize.height / 2);
        this.container.addChild(this.slotMachineSprite);


        this.logoSprite = new PIXI.Sprite(textures['robAPlayerLogo'].texture);
        this.logoSprite.anchor.set(0.5);
        this.logoSprite.position.set(this.logoPosition.x, this.logoPosition.y);
        this.container.addChild(this.logoSprite);

        this.numberMask = new PIXI.Graphics();
        this.numberMask.beginFill(0xffffff);
        this.numberMask.drawRect(
            this.maskPos.x - this.maskSize.width / 2,
            this.maskPos.y - this.maskSize.height / 2,
            this.maskSize.width,
            this.maskSize.height
        );
        this.numberMask.endFill();
        this.container.addChild(this.numberMask);
        this.multipliersContainer.mask = this.numberMask;

    }

    update(dt: number) {
        // dt = 10000
        if (dt > 300) { return; }
        dt /= 1000;

        this.updateSlotMachine(dt);
        this.updateLogo(dt);
        this.updateNumbers(dt);

        this.raysSprite.rotation += 20 * 0.0174533 * dt;
    }

    setPosition(newPosition: Point, anchorPosition: AnchorPosition) {
        if (anchorPosition === AnchorPosition.middleCenter) {
            this.container.position.set(newPosition.x - this.containerSize.width / 2,
                newPosition.y - this.containerSize.height / 2);
        } else if (anchorPosition === AnchorPosition.topLeft) {
            this.container.position.set(newPosition.x, newPosition.y);
        }
    }

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };


    setPlayers(affectedPlayers: RobAPlayerPlayer[], initiatorPlayer: RobAPlayerPlayer) {
        this.initiatorPlayer = initiatorPlayer
        this.initiatorPlayerStartChips = initiatorPlayer.Chips



        const initiatorAvatar = this.createAvatar(initiatorPlayer)
        initiatorAvatar.position.set(480 - initiatorAvatar.width - 20, 295)
        this.container.addChild(initiatorAvatar)

        let playerName = new PIXI.Text(`Casted by: ${initiatorPlayer.Name}`, {
            fontFamily: 'Saira Semi Condensed',
            fontSize: 24,
            fill: '#ffffff',
            fontWeight: '500'
        });
        playerName.anchor.set(0.5, 0.5);
        playerName.position.set(480, 400);

        this.initiatorPlayerChips = new PIXI.Text(`${initiatorPlayer.Chips}`, {
            fontFamily: 'Saira Semi Condensed',
            fontSize: 24,
            fill: '#fff167',
            fontWeight: '500'
        });

        this.initiatorPlayerChips.anchor.set(0.5, 0.5);
        this.initiatorPlayerChips.position.set(480, 432);

        this.container.addChild(this.initiatorPlayerChips)
        this.container.addChild(playerName)



        let affectedPlayersSorted = this.array_move(affectedPlayers, affectedPlayers.findIndex(affectedPlayer => affectedPlayer.Affected), 1)

        // #1 Adding numbers
        let index = 0
        for (const affectedPlayer of affectedPlayersSorted) {
            index++
            const background = new PIXI.Sprite(this.textures['spinNumberBlock1'].texture);
            background.anchor.set(0.5);
            background.position.set(this.containerSize.width / 2, this.containerSize.height / 2 - 30 + (index * background.height));

            let playerAvatar = this.createAvatar(affectedPlayer, true)
            playerAvatar.position.set(220, this.containerSize.height / 2 - 60 + (index * background.height));

            let playerName = new PIXI.Text(`${affectedPlayer.Name}`, {
                fontFamily: 'Saira Semi Condensed',
                fontSize: 24,
                fill: '#ffffff',
                fontWeight: '500'
            });
            playerName.anchor.set(0, 0.5);
            playerName.position.set(350, this.containerSize.height / 2 - 40 + (index * background.height));


            let numberOfChips = new PIXI.Text(`${affectedPlayer.Chips}`, {
                fontFamily: 'Saira Semi Condensed',
                fontSize: 24,
                fill: '#fff167',
                fontWeight: '500'
            });

            numberOfChips.anchor.set(0, 0.5);
            numberOfChips.position.set(350, this.containerSize.height / 2 - 10 + (index * background.height));
            this.multipliersContainer.addChild(background);
            this.multipliersContainer.addChild(playerName);

            if (affectedPlayer.Affected) {
                this.affectedPlayer = affectedPlayer;
                this.affectedPlayerChips = numberOfChips
                this.affectedPlayerChipsMinusAmount = Math.round(affectedPlayer.robbedAmount * 0.02)
                this.multipliersContainer.addChild(this.affectedPlayerChips);
                this.affectedPlayerStartChips = affectedPlayer.Chips

            } else {
                this.multipliersContainer.addChild(numberOfChips);
            }

            if (affectedPlayer.UsedImmunity) {
                const immunity = new PIXI.Sprite(this.textures['robAPlayerImmunity'].texture);
                immunity.anchor.set(0.5);
                immunity.scale.set(0.1)
                immunity.position.set(this.containerSize.width - 300, this.containerSize.height / 2 - 25 + (index * background.height))
                this.multipliersContainer.addChild(immunity);

            }

            this.multipliersContainer.addChild(playerAvatar);

        }

        this.multipliersContainer.filters = [this.blurFilter]
        this.blurFilter.blurY = 1;

        this.multipliersContainer.position.set(0, 0)

        this.minNumYPos = -this.multipliersContainer.height + 82
        this.maxNumYPos = 0

        // #2 Setting Target numbers;

        this.targetPosition = - 84 * affectedPlayers.findIndex(affectedPlayer => affectedPlayer.Affected); // index



    }


    private updateLogo(dt: number) {
        if (this.logoScale > 1.10) {
            this.dScale *= -1;
        } else if (this.logoScale < 0.95) {
            this.dScale *= -1;
        }
        this.logoScale += this.dScale;
        this.logoSprite.scale.set(this.logoScale);
    }

    private updateSlotMachine(dt: number) {
        this.animTimer += dt;
        if (this.animTimer > 0.15) {
            this.animTimer = 0;
            this.animIndex++;
            if (this.animIndex > 1) { this.animIndex = 0; }

            if (this.numbersState === RobAPlayerNumbersState.done) {
                this.slotMachineSprite.texture = this.animTextures[this.animIndex + 2];
            } else {
                this.slotMachineSprite.texture = this.animTextures[this.animIndex];

            }
        }
    }


    private updateNumbers(dt: number) {
        const speedAccelator = 10;

        switch (this.numbersState) {
            case RobAPlayerNumbersState.idle: {
                this.numbersTempTimer += dt;
                this.multipliersContainer.position.y += speedAccelator;
                if (this.numbersTempTimer > 0.5) {
                    this.numbersTempTimer = 0;
                    this.numbersState = RobAPlayerNumbersState.stopping;
                    if (this.targetPosition > this.multipliersContainer.position.y) {
                        this.targetLength = (this.maxNumYPos - this.minNumYPos) + (this.targetPosition - this.multipliersContainer.position.y);
                    } else {
                        this.targetLength = (this.maxNumYPos - this.minNumYPos) - (this.multipliersContainer.position.y - this.targetPosition);
                    }
                }
                break;
            }
            case RobAPlayerNumbersState.stopping: {
                const percent = 1 - (this.currentLength / this.targetLength);
                const speed = speedAccelator * percent + 1 - percent;
                this.currentLength += speed;
                this.multipliersContainer.position.y += speed;
                if (this.currentLength >= this.targetLength) {
                    this.numbersState = RobAPlayerNumbersState.done;
                }
                break;
            }
            case RobAPlayerNumbersState.done: {
                this.multipliersContainer.filters = []

                this.numbersTempTimer += dt;


                if (this.affectedPlayer.UsedImmunity) {
                    // TEXT ANIMATION
                    const textContainer = new PIXI.Container();

                    const spellBlocked = new PIXI.Text(`Spell blocked!`, { fontFamily: 'Saira Semi Condensed', fontSize: 24, fill: '#e61b10', fontWeight: '400' });
                    const hasImmunity = new PIXI.Text(`${this.affectedPlayer.Name} has Immunity!`, { fontFamily: 'Saira Semi Condensed', fontSize: 24, fill: '#fff', fontWeight: '400' });
                    hasImmunity.position.set(spellBlocked.width + 5, 0)

                    textContainer.addChild(spellBlocked)
                    textContainer.addChild(hasImmunity)


                    textContainer.position.set(480 - textContainer.width / 2, 635 - textContainer.height / 2);


                    this.infoTextBackground = new PIXI.Graphics();
                    this.infoTextBackground.beginFill(0x000000, 0.9);
                    this.infoTextBackground.drawRect(0, 0, textContainer.width + 20, textContainer.height + 20);
                    this.infoTextBackground.endFill();

                    this.infoTextBackground.position.set(textContainer.position.x - 10, textContainer.position.y - 10)

                    this.container.addChild(this.infoTextBackground);
                    this.container.addChild(textContainer);

                    if (this.numbersTempTimer > 3) {
                        this.onShowDone.next();
                    }
                } else {
                    if (this.numbersTempTimer < 2) {
                        if (!this.infoText) {


                            // TEXT ANIMATION
                            this.infoText = new PIXI.Text(`${this.initiatorPlayer.Name} robbed player ${this.affectedPlayer.Name} for ${this.affectedPlayer.robbedAmount} chips`, {
                                fontFamily: 'Saira Semi Condensed',
                                fontSize: 24,
                                fill: '#fff',
                                fontWeight: '400'
                            });
                            this.infoText.anchor.set(0.5, 0.5)
                            this.infoText.position.set(480, 635);


                            this.infoTextBackground = new PIXI.Graphics();
                            this.infoTextBackground.beginFill(0x000000, 0.9);
                            this.infoTextBackground.drawRect(0, 0, this.infoText.width + 20, this.infoText.height + 20);
                            this.infoTextBackground.endFill();


                            this.infoTextBackground.position.set(this.infoText.position.x - this.infoText.width / 2 - 10, this.infoText.position.y - this.infoText.height / 2 - 10)
                            this.container.addChild(this.infoTextBackground);
                            this.container.addChild(this.infoText);
                        }
                    } else if (this.numbersTempTimer > 2) {
                        //       this.onShowDone.next();


                        if (this.affectedPlayer.Chips > 0) {

                            if ((this.affectedPlayer.Chips - this.affectedPlayerChipsMinusAmount) < (this.affectedPlayerStartChips - this.affectedPlayer.robbedAmount)) {
                                this.affectedPlayer.Chips = this.affectedPlayerStartChips - this.affectedPlayer.robbedAmount
                                this.initiatorPlayer.Chips = this.initiatorPlayerStartChips + this.affectedPlayer.robbedAmount
                            } else {
                                this.affectedPlayer.Chips -= this.affectedPlayerChipsMinusAmount;
                                this.initiatorPlayer.Chips += this.affectedPlayerChipsMinusAmount;
                            }


                            this.affectedPlayerChips.tint = 0x450606
                            this.affectedPlayerChips.text = `${this.affectedPlayer.Chips}`

                            this.initiatorPlayerChips.tint = 0x01EF03
                            this.initiatorPlayerChips.text = `${this.initiatorPlayer.Chips}`
                        }








                        // Arrows
                        if (!this.arrowUp) {
                            this.arrowUp = new PIXI.Sprite(this.textures['robAPlayerArrowUp'].texture);
                            this.container.addChild(this.arrowUp)
                            this.arrowUp.anchor.set(0.5, 0.5);
                            this.arrowUp.scale.set(0.5)
                            this.arrowUp.position.set(this.initiatorPlayerChips.position.x - this.initiatorPlayerChips.width / 2 - 20, this.initiatorPlayerChips.position.y)

                        }

                        if (!this.arrowDown) {
                            this.arrowDown = new PIXI.Sprite(this.textures['robAPlayerArrowDown'].texture);
                            this.container.addChild(this.arrowDown)
                            this.arrowDown.anchor.set(0, 0);
                            this.arrowDown.scale.set(0.5)
                            this.arrowDown.position.set(this.affectedPlayerChips.position.x - 20, 541)

                        }

                        // if(this.arrowAnimatioZoomDirection){
                        //     this.arrowUp.scale.set(1)
                        //     this.arrowDown.scale.set(1)
                        //     this.arrowAnimatioZoomDirection = false;
                        // }else{
                        //     this.arrowUp.scale.set(0.5)
                        //     this.arrowDown.scale.set(0.5)
                        //     this.arrowAnimatioZoomDirection = true
                        // }



                    }

                    if (this.numbersTempTimer > 5) {
                        this.onShowDone.next();
                    }
                }


                break;
            }
        }

        if (this.multipliersContainer.position.y > this.maxNumYPos) {
            this.multipliersContainer.position.y = this.minNumYPos;
        }
    }


    private createAvatar(player: RobAPlayerPlayer, isSmall?: boolean) {
        const avatarContainer = new PIXI.Container();
        let avatarTexture: PIXI.Texture;
        let avatarSprite: PIXI.Sprite;
        let avatarMask: PIXI.Graphics;

        if (player.AvatarURL) {
            const tempLoader = new PIXI.Loader()
            tempLoader.add('avatar', player.avatar, { crossOrigin: 'anonymous' });
            tempLoader.load((loader, resources) => {
                avatarSprite.texture = resources['avatar'].texture;
                avatarSprite.scale.set(circleWidth / Math.abs(circleWidth - avatarSprite.texture.baseTexture.width) + 0.1)

                loader.reset();
            });
        } else {
            avatarTexture = this.textures['emptyAvatar'].texture;
        }

        // ***

        const circleWidth = 218
        avatarMask = new PIXI.Graphics();
        avatarMask.beginFill(0x00FF00, 1);
        avatarMask.lineStyle(0);
        avatarMask.drawCircle(274, 122, circleWidth / 2);
        avatarMask.endFill();
        avatarContainer.addChild(avatarMask);
        avatarMask.isMask = true;

        const avatarMask2 = new PIXI.Graphics();
        avatarMask2.beginFill(0xFFFFFF, 1);
        avatarMask2.lineStyle(0);
        avatarMask2.drawCircle(273, 121, circleWidth / 2 + 2);
        avatarMask2.endFill();
        avatarContainer.addChild(avatarMask2);

        avatarSprite = new PIXI.Sprite(avatarTexture);
        avatarSprite.anchor.set(0.5);
        avatarSprite.position.set(274, 122)
        avatarSprite.mask = avatarMask;
        avatarSprite.scale.set(circleWidth / Math.abs(circleWidth - avatarSprite.texture.baseTexture.width) + 0.1)

        avatarContainer.addChild(avatarSprite);

        avatarContainer.scale.set(isSmall ? 0.275 : 0.35)
        return avatarContainer
    }
}
