import { NavTableData } from '../../shared/models/nav-table-data';
import { TournamentSummary } from '../../shared/models';

export class NavItemType {

    itemType: ENavItem;
    isActive = false;
    navTableData: NavTableData;
    tournamentSummaries: TournamentSummary;

    constructor(item: ENavItem, isActive?: boolean) {
        this.itemType = item;
        this.isActive = isActive;
    }

    setTableData(navTableData: NavTableData) {
        this.navTableData = navTableData;
    }

    setTournamentData(tournamentSummaries: TournamentSummary) {
        this.tournamentSummaries = tournamentSummaries;
    }

    setStatus(isActive: boolean) {
        this.isActive = isActive;
    }

    getStatus() {
        return this.isActive;
    }
}

export enum ENavItem {
    Lobby = 0,
    Game = 1,
    Tournament = 2,
    Settings = 3,
    Cashier = 3,
    SkinSettings = 4,
    Shop = 5,
    Deposit = 6,
    CasinoGame = 7,
    TransactionsHistory = 8
}
