import * as PIXI from 'pixi.js';


export class GenericTextArray {
    public container: PIXI.Container;
    private text: PIXI.Text;
    private textStyle: PIXI.TextStyle = new PIXI.TextStyle({
        fontFamily: 'Saira Semi Condensed',
        fontSize: 26,
        fill: '#FFF',
        fontWeight: '400'
    });

    constructor(position: PIXI.Point) {

        this.container = new PIXI.Container();
        this.container.position.set(position.x, position.y);
        this.container.alpha = 0.2

        this.text = new PIXI.Text('', this.textStyle);
        this.text.anchor.set(0.5, 0.5);
        this.text.style.align = 'center'
        this.container.addChild(this.text);
    }

    public setText(texts: string[], separator: string, useSpace?: boolean) {
        this.text.text = '';
        let temp = '';

        texts.forEach((t, i) => {
            temp += t + separator + (useSpace ? ' ' : '');
        });
        temp = temp.slice(0, temp.lastIndexOf(separator));

        this.text.text = temp;
    }
}
