import { Injectable } from '@angular/core';
import * as bowser from 'bowser';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import { AppWebSocketService } from './app-web-socket.service';
import { environment } from '../../../environments/environment';
import { from, Observable, of } from 'rxjs';
import {
  ClientInfo,
  ClientCredential,
  MemberProfile,
  TableInfo,
  Gift,
  LobbyFilterOptions,
  Settings
} from '../../shared/models';
import {
  Authenticate,
  User
} from '../../shared/models/user';
import {
  RequestMessage,
  PlayerStatus,
  WaitingListCommand,
  GameLayoutTypes,
} from '../../shared/enums/poker-types';
import { Gateway, Currency } from '../../shared/enums';
import { Base64Encode } from '../../shared/helpers/base64-encode';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { MemberPreferencesRunItTwice } from '../../shared/models/member-preferences';
import { BountySpellVariant } from '../../shared/models/bounty-spell';


export interface CasinoGamesCategory {
  id: number;
  name: string; // Name of category to be displayed in the app lobby/menu
  showInMainLobby: boolean; // If true item should be displayed in the main lobby
  showInMainMenu: boolean;
  orderInMainMenu: number;
  showInTopMenu: boolean;
  orderInTopMenu: number;
  highlighted: boolean;
  integrator: string; // Name of the integrator (casino), eg: Hub88
  urlThumb: string;
}


export interface CasinoCategoryWithGames {
  categoryName: string;
  categoryUrlThumb: string;
  categoryId: 1,
  integrator: string;
  showInLobby: false,
  showInMainMenu: true,
  orderInMainMenu: 1,
  showInTopMenu: true,
  orderInTopMenu: 1,
  isHighlighted: true,
  games: {
    name: string;
    gameCode: string;
    urlThumb: string;
  }[]
}

@Injectable()
export class DataManagerService {

  public exchange = {} as any;
  private depositWindow;
  messageReceivedObservable: Observable<any>;

  readonly PING_NUMBER = Math.round(Math.random() * 1000000000000)

  username: string;
  password: string;
  clientName: string;
  skin: string;
  device: string;
  version: string;
  platform: number;
  guid: string;

  constructor(
    private service: AppWebSocketService,
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.messageReceivedObservable = service.messageReceivedObservable;
  }

  getGameToken() {
    return this.http.post<any>(`${this.configService.config.httpUrl}/ApiGame/GameToken`, {
      username: this.username,
      password: this.password,
      clientName: this.clientName,
      skin: this.skin,
      device: this.device,
      version: this.version,
      platform: this.platform,
      guid: this.guid
    })
  }


  ensureTrailingSlash(url: string): string {
    // Trim any leading or trailing whitespace from the URL
    url = url.trim();

    // Check if the last character is a slash
    if (url.endsWith('/')) {
      return url;
    }

    // Append a slash if not present
    return url + '/';
  }


  getCasinoGamesCategories(token: string) {
    return this.http.get<{ categories: CasinoGamesCategory[], Success: boolean }>(`${this.ensureTrailingSlash(this.configService.config.httpUrl)}v1/casino/games/categories?SessionId=${token}&casinoName=hub88`, { headers: { Token: token } })
  }

  getAllCasinoGames(token) {
    return this.http.get<{ categoriesWithGames: CasinoGamesCategory[], Success: boolean }>(`${this.ensureTrailingSlash(this.configService.config.httpUrl)}v1/casino/games?SessionId=${token}&casinoName=hub88&limit=0`, { headers: { Token: token } })
  }

  getCasinoGames(token, category, limit) {
    return this.http.get<{ categoriesWithGames: CasinoGamesCategory[], Success: boolean }>(`${this.ensureTrailingSlash(this.configService.config.httpUrl)}v1/casino/games?SessionId=${token}&casinoName=hub88&categoryId=${category}&limit=${limit}`, { headers: { Token: token } })
  }

  getCasinoGameLaunchUrl(token: string, gameCode: string, currency: string, isMobile = false) {
    const casinoName = 'hub88';
    return this.http.get<{ url: string, token: string, gameCode: string }>(`${this.ensureTrailingSlash(this.configService.config.httpUrl)}v1/casino/games/launchUrl?SessionId=${token}&gameCode=${gameCode}&currency=${currency}&isMobile=${isMobile}&casinoName=${casinoName}`, { headers: { Token: token } })
  }


  getPlayersTransactionHistory(offset: number, limit: number, type: string, currencyId: number, token: string) {
    return this.http.get<{ transactions: any[], Success: boolean }>(`${this.ensureTrailingSlash(this.configService.config.httpUrl)}v1/player/transactions/get?SessionId=${token}&limit=${limit}&offset=${offset}&currency=${currencyId}&type=${type}`, { headers: { Token: token } })
  }


  sendClientInfo(): Observable<any> {
    return from(new Promise(async resolve => {


      let guid = localStorage.getItem(`guid`) ?? null

      if (!guid) {
        if (environment.production) {
          try {
            await FingerprintJS.load({ token: 'LKv84d9KPsyj2Kv4juhs' })
              .then(fp => fp.get())
              .then(result => {
                guid = result.visitorId
              })
          } catch (error) {
            guid = `GUID_ERR_${Math.floor(Math.random() * 1000000)}_AUTO_ID`

          }
        } else {
          guid = `AUTO_ID_${Math.floor(Math.random() * 1000000)}_TEST`
        }

      }

      if (guid && guid !== 'null') {
        localStorage.setItem(`guid`, guid)

        this.service.sendData({
          type: 3,
          Str1: `Angular`, // Name
          Str2: this.configService.config.skinName, // Skin/ReferralName
          Str3: 'v2.0', // Client Version
          Str4: this.getBrowserData(), // OsVersion
          Str5: guid, // Unique guid to identify the device (can be generated locally) this will let connection overtake existing one.
          Value2: 2 // platform (1 for Windows, 2: HTML, 3:Android, 4:iOS, 7:Mac, 8:Bot)
        } as ClientInfo)
      }

      this.guid = guid;
      this.skin = this.configService.config.skinName;
      this.clientName = 'Angular';
      this.device = this.getBrowserData();
      this.version = 'v2.0';
      this.platform = 2;


      resolve(null)
    }));
  }

  private getBrowserData(): string {
    return (bowser.name + ' ' + bowser.version + ' / ' + bowser.osname + ' ' + bowser.osversion);
  }









  login(auth: Authenticate) {

    if (!auth) { return }
    const clientCredential: ClientCredential = {
      type: RequestMessage.Connect,
      Str1: auth.username,
    };

    if (auth.token) {
      clientCredential.type = RequestMessage.ConnectWithAuthToken;
      clientCredential.Str1 = auth.token;
    }
    if (auth.securityCode) {
      clientCredential.Str4 = auth.securityCode;
    } else {
      clientCredential.Str2 = auth.password;

    }

    this.username = auth.username;
    this.password = auth.password;

    this.service.sendData(clientCredential);
  }

  getTableFilters(currency: number) {
    this.service.sendData({
      type: RequestMessage.GetTableFilters,
      currency
    });
  }

  logout() {
    this.service.sendData({
      type: RequestMessage.Disconnect,
    });
    this.service.disconnect();
  }

  checkPromoCode(promoCode) {
    this.service.sendData({
      type: RequestMessage.CheckPromoCode,
      Str1: promoCode,
    });
  }

  sendRegistrationData(value) {
    this.service.sendData({
      type: RequestMessage.MemberProfile,
      MemberProfile: value,
    });
  }

  requestSecurityCode(email: string, skinName: string) {
    this.service.sendData({
      type: RequestMessage.AccountRequestSecurityCode,
      Str1: email,
      Str2: skinName
    });
  }

  sendChangePasswordByCode(username: string, securityCode: string) {
    this.service.sendData({
      type: RequestMessage.AccountChangePassword,
      Str1: username,
      Str4: securityCode,
    });
  }

  sendPing() {
    this.service.sendData({
      type: RequestMessage.Ping,
      Value2: this.PING_NUMBER
    });
  }

  getHandReplayList() {
    const item = {
      type: RequestMessage.HandHistory,
      Value: 0
    };
    this.service.sendData(item);
  }


  submitAskQuestion(requestId: number, value: string) {
    this.service.sendData({
      type: requestId,
      Str1: value,
    });
  }

  sendDepositRequest(gatewayName: string, amount: number, currency: Currency, promoCode: string, depositWindow?: Window) {
    this.depositWindow = depositWindow;
    this.service.sendData({
      type: RequestMessage.DepositInitiate,
      Value2: amount,
      Value3: currency,
      Str1: promoCode,
      Str2: gatewayName,
    });
  }



  // REMOVE obrisati kad obrisem dialogs...
  sendDepositRequestNew(gatewayName: string, amount: number, currency: Currency, promoCode: string, depositWindow?: Window) {
    this.depositWindow = depositWindow;
    this.service.sendData({
      type: RequestMessage.DepositInitiate,
      Value2: amount,
      Value3: currency,
      Str1: promoCode,
      Str2: gatewayName,
    });
  }
  // --- - -- - -


  sendWithdrawalRequest(gatewayId: string, amount: number, currency: Currency, extraInfo: string) {
    this.service.sendData({
      type: RequestMessage.WithdrawalRequest,
      Value2: amount,
      Currency: currency,
      Str1: extraInfo,
      Str2: gatewayId
    });
  }

  sendCancelWithdrawal(currency: Currency) {
    this.service.sendData({
      Type: RequestMessage.WithdrawalCancel,
      Currency: currency
    });
  }

  sendTdsConvertRequest(tdsAmount: number) {
    const convert = {
      type: RequestMessage.TDSTransfer,
      Value: tdsAmount
    };
    this.service.sendData(convert);
  }

  uploadImage(file: File, type: number) {
    const base64Encode = new Base64Encode();
    base64Encode.encodeFile(file).subscribe(base64Image => {
      this.service.sendData({
        type: RequestMessage.ImageUpload,
        Str1: base64Image,
        Value: type
      });
    });
  }

  getSmsForVerfiction() {
    this.service.sendData({
      type: RequestMessage.RequestPhoneCode,
    });
  }

  sendChangePassword(newPassword: string, oldPassword?: string) {
    this.service.sendData({
      type: RequestMessage.AccountChangePassword,
      Str1: newPassword,
      Str2: oldPassword
    });
  }

  sendPhoneVerificationData(code: string) {
    this.service.sendData({
      type: 212,
      Str1: code
    });
  }

  sendMemberProfile(memberProfile: MemberProfile) {
    this.service.sendData({
      type: RequestMessage.MemberProfile,
      MemberProfile: memberProfile,
    });
  }

  verifyUsernameUnique(username: string) {
    this.service.sendData({
      type: RequestMessage.MemberProfile,
      Str1: username,
    });
  }

  verifyEmailUnique(email: string) {
    this.service.sendData({
      type: RequestMessage.MemberProfile,
      Str1: email,
    });
  }

  getTournamentSummary(tournamentId: number) {
    this.service.sendData({
      type: RequestMessage.GetTournamentSummary,
      IdTournament: tournamentId
    });
  }

  getTransactionsHistory(currency: Currency) {
    this.service.sendData({
      type: RequestMessage.RequestTransactionHistory,
      Currency: currency,
    });
  }

  subscribeLiveLobby(turnOn: boolean) {
    this.service.sendData({
      type: RequestMessage.SubscribeLiveLobby,
      Value: turnOn ? 1 : 0
    });
  }

  requestTableData(lobbyFilter: LobbyFilterOptions, currency: number) {
    let data = {} as any;

    Object.keys(lobbyFilter).forEach(function (item) {
      if (Array.isArray(lobbyFilter[item])) {
        data[item] = !lobbyFilter[item][0] ? undefined : lobbyFilter[item];
      } else if (
        (
          item === 'Stakes' ||
          item === 'Limits' ||
          item === 'Speeds' ||
          item === 'NbSeats'
        ) &&
        typeof lobbyFilter[item] == 'number') {
        data[item] = [lobbyFilter[item]];
      } else {
        data[item] = lobbyFilter[item];
      }
      delete data.currency;
      delete data.stakes;
      delete data.limit;
    });

    this.getTableFilters(currency);

    this.service.sendData({
      Type: RequestMessage.GetTables, //  Value1 = moneyType, Value2: filters [various combined], Value3: filter by number of seats
      Currency: currency,
      Filters: data
    });
  }

  getTableDetail(tableId: number) {
    this.service.sendData({
      type: RequestMessage.GetTableDetail,
      IdTable: tableId,
    });
  }

  sendMTTView(tournamentId: number, view: boolean, password?: string) {
    this.service.sendData({
      type: RequestMessage.MTTView,
      IdTournement: tournamentId,
      Value2: view ? 1 : 0,
      Str1: password,
    });
  }

  sendMTTRegister(tournamentId: number, register: boolean) {

    this.service.sendData({
      type: RequestMessage.MTTRegister,
      IdTournement: tournamentId,
      Value2: register ? 1 : 0
    });
  }

  runItTwice(value: MemberPreferencesRunItTwice) {
    this.service.sendData({
      type: RequestMessage.SetRunItTwice,
      Value: value
    });
  }

  runItTwiceTable(value: MemberPreferencesRunItTwice, tableId: number) {
    this.service.sendData({
      type: RequestMessage.SetRunItTwice,
      Value: value,
      IdTable: tableId
    });
  }

  r2tAnswerAskQuestion(callback: number, idTable: number, value: number) {
    this.service.sendData({
      type: callback,
      IdTable: idTable,
      Value2: value
    });
  }

  muckCard(turnOn: boolean) {
    this.service.sendData({
      type: RequestMessage.SetAutoMuck,
      Value: turnOn
    });
  }

  jumpToTable(turnOn: boolean) {
    this.service.sendData({
      type: RequestMessage.SetJumpToTable,
      Value: turnOn
    });
  }

  //  unlockGift(giftId: number, pointExchangeRateValue: number) {
  unlockGift(giftId: number) {
    this.service.sendData({
      type: RequestMessage.GiftUnlock,
      Value: giftId,
      // Value2: pointExchangeRateValue
    });
  }

  getGiftIdForUnlock(user: User, settings: Settings): Gift {
    return user.gifts.find((gift: Gift) => {
      if (gift.Type === 3 && gift.Currency == user.selectedCurrency.Id) {
        return true;
      } else {
        if (gift.Currency == user.selectedCurrency.Id
          && gift.AmountRemaining >= settings.PointExchangeRateValue) {
          return true;
        } else {
          return false;
        }
      }
    });
  }

  sendTakeSeat(tableId: number, seatPosition: number) {
    this.service.sendData({
      type: RequestMessage.TakeSeat,
      IdTable: tableId,
      Value2: seatPosition
    });
  }

  sendShowCards(tableId: number, currentHandNumber: number, showCardsConfirmed: number) {
    this.service.sendData({
      type: RequestMessage.Muck,
      IdTable: tableId,
      HandNumber: currentHandNumber,
      Value2: showCardsConfirmed
    });
  }

  sendFold(tableId: number, currentHandNumber: number) {
    this.service.sendData({
      type: RequestMessage.Bid,
      IdTable: tableId,
      Value2: -1,
      HandNumber: currentHandNumber
    });
  }

  sendLeaveSeat(tableId: number, playerStatus: PlayerStatus = PlayerStatus.LeaveSeat) {
    this.service.sendData({
      type: RequestMessage.ChangeStatus,
      IdTable: tableId,
      Value2: playerStatus,
    });
  }

  sendLeaveTable(tableId: number) {
    this.service.sendData({
      type: RequestMessage.LeaveTable,
      IdTable: tableId
    });
  }

  sendBuyChips(tableId: number, addChipsValue: number) {
    this.service.sendData({
      type: RequestMessage.BuyChips,
      IdTable: tableId,
      Value2: addChipsValue
    });
  }

  sendImBack(tableId: number) {
    this.service.sendData({
      type: RequestMessage.ChangeStatus,
      IdTable: tableId,
      Value2: PlayerStatus.Ready,
    });
  }

  sendCheck(tableId: number, checkValue: number, handNumber: number) {
    this.service.sendData({
      type: RequestMessage.Bid,
      IdTable: tableId,
      Value2: checkValue,
      HandNumber: handNumber
    });
  }

  sendCall(tableId: number, callValue: number, handNumber: number) {
    this.service.sendData({
      type: RequestMessage.Bid,
      IdTable: tableId,
      Value2: callValue,
      HandNumber: handNumber
    });
  }

  sendRaise(tableId: number, raiseValue: number, handNumber: number) {
    this.service.sendData({
      type: RequestMessage.Bid,
      IdTable: tableId,
      Value2: raiseValue,
      HandNumber: handNumber
    });
  }

  sendBet(tableId: number, betValue: number, handNumber: number) {
    this.service.sendData({
      type: RequestMessage.Bid,
      IdTable: tableId,
      Value2: betValue,
      HandNumber: handNumber
    });
  }

  sendAllIn(tableId: number, allInValue: number, handNumber: number) {
    this.service.sendData({
      type: RequestMessage.Bid,
      IdTable: tableId,
      Value2: allInValue,
      HandNumber: handNumber
    });
  }

  sendChatMessage(tableId: number, message: string) {
    this.service.sendData({
      type: RequestMessage.Chat,
      IdTable: tableId,
      Str1: message,
    });
  }

  sendLobbyChatMessage(message: string) {
    this.service.sendData({
      type: RequestMessage.Chat,
      Str1: message,
    });
  }

  sendMoneyTransfer(amount: number, currency: string, username: string, note: string) {
    this.service.sendData({
      type: RequestMessage.TransferMoney,
      Value: amount,
      Value2: currency,
      Str1: username,
      Str2: note
    });
  }

  uploadAvatar(blobImage) {
    this.service.sendData({
      type: RequestMessage.AvatarUpload,
      Str1: blobImage,
    });
  }

  getHandStrength(variant: number, playerCards: string, publicCards: string, hiLo: boolean, nbPlayers: number) {
    this.service.sendData({
      type: RequestMessage.HandStrength,
      Variant: variant,
      PlayerCards: playerCards,
      PublicCards: publicCards,
      HiLo: hiLo,
      NbPlayers: nbPlayers
    });
  }

  sendSubscribeLiveLobbySelected(idTable: number) {
    this.service.sendData({
      type: RequestMessage.SubscribeLiveLobbySelected,
      IdTable: idTable
    });
  }

  joinWaitingList(idTable: number) {
    this.service.sendData({
      type: RequestMessage.TableSetWaiting,
      IdTable: idTable,
      Value2: WaitingListCommand.Join,
    });
  }

  joinTable(idTable: number, password?: string) {
    this.service.sendData({
      type: RequestMessage.JoinTable,
      IdTable: idTable,
      Value2: password
    });
  }

  leaveWaitingList(idTable: number) {
    this.service.sendData({
      type: RequestMessage.TableSetWaiting,
      IdTable: idTable,
      Value2: WaitingListCommand.Leave,
    });
  }



  sendExchangeRateRequest(fromCurrency: Currency, toCurrency: Currency) {
    this.service.sendData({
      type: RequestMessage.ExchangeRate,
      Currency: fromCurrency,
      Value: toCurrency,
    });
  }

  getCurrencyExchange() {
    return this.http.get<any>(`${this.configService.config.httpUrl}/api/exchange-rate/all`)
      .pipe(
        tap(exchange => this.exchange = exchange),
      );
  }

  sendSelfExclude(period: number, password: string) {
    this.service.sendData({
      Type: RequestMessage.SelfExclude,
      Value: period,
      Str1: password
    });
  }

  requestBlindSchedule(idTournament: number) {
    this.service.sendData({
      Type: RequestMessage.RequestBlindSchedule,
      IdTournament: idTournament,
    });
  }

  getCasinoToken(type: string) {
    this.service.sendData({
      Type: RequestMessage.GetAuthToken,
      Str1: type,
    });
  }

  invalidateCasinoToken() {
    this.service.sendData({
      Type: RequestMessage.InvalidateAuthToken,
    });
  }




  getCasinosInfo() {
    this.service.sendData({
      Type: RequestMessage.CasinoInfo,
    });
  }

  getChatLobbyHistory(oldestMessageId?: number, numberOfMessages?: number) {
    this.service.sendData({
      type: RequestMessage.GetChatLobbyHistory,
      Value: oldestMessageId,
      Value2: numberOfMessages
    });
  }

  getMixTablesDetails(tableId: string) {
    this.service.sendData({
      type: RequestMessage.GetMixTablesDetails,
      value: tableId
    });
  }


  askHandReplayData(handId: number) {
    this.service.sendData({
      type: RequestMessage.HandReplay2,
      Value: handId // 253946//253063, //target hand number
    });
  }

  subscribeStatsPlayerOnline() {
    this.service.sendData({
      type: RequestMessage.SubscribeStatsPlayerOnline
    });
  }


  submitPrivateTablePassword(idTable: number, password: string) {
    this.service.sendData({
      type: RequestMessage.PrivateTablePassword,
      IdTable: idTable,
      Str1: password
    });
  }


  getEmotikensPackages() {
    this.service.sendData({
      type: RequestMessage.EmotikensGetPackages
    });
  }

  emotikensBuyPackage(emotikensPackageId: number) {
    this.service.sendData({
      type: RequestMessage.EmotikensBuyPackage,
      Value: emotikensPackageId
    });
  }

  currencyGetPackages(currencyId: number) {
    this.service.sendData({
      type: RequestMessage.CurrencyGetPackages,
      Currency: 255//currencyId
    });
  }
  emoticonsGetList() {
    this.service.sendData({
      type: RequestMessage.EmoticonsGetList
    });
  }

  emoticonUnlock(emoticonId: number) {
    this.service.sendData({
      type: RequestMessage.EmoticonUnlock,
      Value: emoticonId
    });
  }


  getTickets(currencyId: number) {
    this.service.sendData({
      type: RequestMessage.GetTickets,
      value: currencyId
    });
  }
  buyTicket(ticketId: number) {
    this.service.sendData({
      type: RequestMessage.BuyTicket,
      Value: ticketId
    });
  }
  currencyBuyPackage(currencyPackageId: number) {
    this.service.sendData({
      type: RequestMessage.CurrencyBuyPackage,
      Value: currencyPackageId
    });
  }

  playerAlignmentInitiate() {
    this.service.sendData({
      type: RequestMessage.PlayerAlignmentInitiate
    });
  }


  sendGameLayout(idTable: number, value: GameLayoutTypes) {
    this.service.sendData({
      type: RequestMessage.NewGameLayout,
      IdTable: idTable,
      Value: value
    });
  }

  sendChatReaction(tableId: number, id: string) {
    this.service.sendData({
      type: RequestMessage.Chat,
      IdTable: tableId,
      Str1: '',
      Value: id
    });
  }


  checkUsernameExist(username: string) {
    this.service.sendData({
      type: RequestMessage.AccountVerifyUsernameUnique,
      Str1: username,
    });
  }



  checkEmailExist(email: string) {
    this.service.sendData({
      type: RequestMessage.AccountVerifyEmailUnique,
      Str1: email,
    });
  }


  updateAccount(memberProfile: Partial<MemberProfile>) {
    this.service.sendData({
      Type: RequestMessage.UpdateAccount,
      MemberProfile: memberProfile,
    });
  }


  shopSettings() {
    this.service.sendData({
      Type: RequestMessage.ShopSettings
    });
  }


  callTimeAccepted(tableId: number) {
    this.service.sendData({
      Type: RequestMessage.CallTimeAccepted,
      IdTable: tableId
    });
  }

  callTimeStatus(tableId: number) {
    this.service.sendData({
      Type: RequestMessage.CallTimeStatus,
      IdTable: tableId
    });
  }

  spellActivation(tableId, value: BountySpellVariant, playerId?: number) {

    if (playerId) {
      this.service.sendData({
        Type: RequestMessage.UseSpellRequest,
        IdTable: tableId,
        Value: value,
        Value2: playerId
      });
      return
    }
    this.service.sendData({
      Type: RequestMessage.UseSpellRequest,
      IdTable: tableId,
      Value: value
    });
  }


  answerAntibot(tableId, answerId: number, antibotId: number, answerValue: number) {
    this.service.sendData({
      Type: RequestMessage.AntiBotAnswer,
      IdTable: tableId,
      Value: answerId,
      Value2: antibotId,
      Value3: answerValue
    });
  }

  sendPlayerFeedback(bugTitle: string, bugDescription: string, bugFile: string) {
    this.service.sendData({
      type: RequestMessage.ReportBug,
      Str1: bugTitle,
      Str2: bugDescription,
      Str3: bugFile,
      Str4: 2,
      Str5: 'v2.0',
    });
  }

}
