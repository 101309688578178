import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { WINDOW } from './window.providers';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _config: any;
  config$ = new ReplaySubject<any>(1);

  set config(data: any) {
    this._config = data;
    this.config$.next(data)
  }

  get config() {
    return this._config;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private httpClient: HttpClient
  ) {
  }

  load(): Promise<any> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    });


    //'https://data-1.fra1.cdn.digitaloceanspaces.com/staging/registration/config/registration1.createpoker.app.json'
    // console.log("@this.window.location.hostname", this.window.location.hostname)
    return this.httpClient.get(`${environment?.dataSource}/config/${this.window.location.hostname.includes('localhost') ? environment?.domainname : this.window.location.hostname}.json`, { headers })
      .pipe(
        tap(configData => {
          configData['settings']['languages'].push({ value: 'fr', displayText: 'French' })
          configData['settings']['languages'].push({ value: 'he', displayText: 'Hebrew' })
          if (configData['code'] === '8dragons-fbif') {
            configData['settings']['languages'] = [
              { value: 'en', displayText: 'English' },
              { value: 'cn', displayText: 'Chinese' }
            ]
            configData['settings']['defaultLanguage'] = 'en'
          }

          if (configData['code'] === 'cwop-fxmv') {
            configData['settings']['defaultLanguage'] = 'br'
          }


          configData['lobbyGameTypeFilters'] = configData['lobbyGameTypeFilters'].map(el => {
            if (el.realValue === 1) {
              el.displayValue = 'page.lobby.cashGames'
            }
            if (el.realValue === 2) {
              el.displayValue = 'page.lobby.sitAndGo'
            }
            if (el.realValue === 4) {
              el.displayValue = 'page.lobby.tourney'
            }
            if (el.realValue === 16) {
              el.displayValue = 'page.lobby.spinAndGo'
            }
            return el;
          })


          configData['newLayout'] =
            configData['code'] === 'newwavepoker-cxzo' ||
            configData['code'] === 'newwavepokertest-kwwa' ||
            configData['code'] === 'checkraise-btkg' ||
            configData['code'] === 'concordebet-gyem' ||
            configData['code'] === 'hunnupoker-esqi' ||
            configData['code'] === 'devryops2-nxwr'


          this.config = configData


          if (this.config.skinSettings.backCards) {
            this.config.skinSettings.backCards = this.config.skinSettings.backCards.map(card => {
              card.cardUrl = `${environment.dataSource}/${card.cardUrl}`;
              return card;
            })
          }

          if (this.config.skinSettings.tables) {
            this.config.skinSettings.tables.landscape = this.config.skinSettings.tables.landscape.map(table => {
              table.tableUrl = `${environment.dataSource}/${table.tableUrl}`;
              return table;
            })
            this.config.skinSettings.tables.portrait = this.config.skinSettings.tables.portrait.map(table => {
              table.tableUrl = `${environment.dataSource}/${table.tableUrl}`;
              return table;
            })
          }

          if (this.config.skinSettings.carpets) {
            this.config.skinSettings.carpets.landscape = this.config.skinSettings.carpets.landscape.map(carpet => {
              carpet.carpetUrl = `${environment.dataSource}/${carpet.carpetUrl}`;
              return carpet;
            })
            this.config.skinSettings.carpets.portrait = this.config.skinSettings.carpets.portrait.map(carpet => {
              carpet.carpetUrl = `${environment.dataSource}/${carpet.carpetUrl}`;
              return carpet;
            })
          }
        })).toPromise()



  }
}
