import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerLevelStatus, PlayerLevelStatusLevel } from '../../../core/services/manager.service';
import { CurrencyInfo } from '../../../shared/models/currency-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Base64Encode } from '../../../shared/helpers/base64-encode';

@Component({
  selector: 'app-feedback-from-player',
  templateUrl: './feedback-from-player.component.html',
  styleUrls: ['./feedback-from-player.component.scss']
})
export class FeedbackFromPlayerComponent implements OnInit {

  form: FormGroup;

  options = [
    "Login", "Lobby", "Game Rules", "Cash Game Table", "Tournament", "Cashier", "Menu", "Other"
  ]
  fileToUpload: File | null = null;


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FeedbackFromPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {}
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      bugTitle: ['', [Validators.required]],
      bugDescription: ['', [Validators.required]],
    });
  }

  handleFileInput(event) {
    this.fileToUpload = event.target.files[0];



    
  }

  sendFeedback() {
    if(!this.form.valid || !this.form.value.bugTitle || !this.form.value.bugDescription || !this.fileToUpload){
      return
    }

    const base64Encode = new Base64Encode();
    base64Encode.encodeFile(this.fileToUpload).subscribe(base64Image => {

      this.dialogRef.close({
        bugTitle: this.form.value.bugTitle,
        bugDescription: this.form.value.bugDescription,
        bugFile: base64Image
      });
    });

  
  }
}
