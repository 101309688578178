export const SuddenDeath = {
    "BountySpellExecuted": {
        "Spell": 1,
        "InitiatorPlayer": {
            "IdPlayer": 1042,
            "Affected": false,
            "InvolvedInSpellExecution": false,
            "UsedImmunity": false,
            "Name": "piksi",
            "Chips": 481,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 1,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 1033,
                "Affected": true,
                "InvolvedInSpellExecution": false,
                "UsedImmunity": false,
                "Name": "binic",
                "Chips": 1020,
                "AvatarURL": "1am1-0052.png",
                "PlayerSpells": {
                    "BountySpell": {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": -2,
                        "ShowSpell": false,
                        "Status": 1
                    },
                    "EarnedSpells": [
                        {
                            "UseSpellAgainst": [

                            ],
                            "SpellVariant": 6,
                            "ShowSpell": true,
                            "Status": 3
                        }
                    ]
                }
            },
            {
                "IdPlayer": 1042,
                "Affected": true,
                "InvolvedInSpellExecution": true, // shortest stack
                "UsedImmunity": false,
                "Name": "piksi",
                "Chips": 481, // 
                "AvatarURL": "Upload/b4578d909f3e4dc0816f46d7a598a475.png",
                "PlayerSpells": {
                    "BountySpell": {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": -2,
                        "ShowSpell": false,
                        "Status": 1
                    },
                    "EarnedSpells": [
                        {
                            "UseSpellAgainst": [

                            ],
                            "SpellVariant": 1,
                            "ShowSpell": true,
                            "Status": 2
                        }
                    ]
                }
            }
        ],
        "Chips": 499,
        "Value": 0
    },
    "Response": "BountySpellExecuted"
}



export const SuddenDeathImmunity = {
    "BountySpellExecuted": {
        "Spell": 1,
        "InitiatorPlayer": {
            "IdPlayer": 1042,
            "Affected": false,
            "InvolvedInSpellExecution": false,
            "UsedImmunity": false,
            "Name": "Shone81",
            "Chips": 481,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 1,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 1033,
                "Affected": true,
                "InvolvedInSpellExecution": false,
                "UsedImmunity": true,
                "Name": "Rocky1",
                "Chips": 1020,
                "AvatarURL": "Upload/1f7cec31a6b44ec2ab3a75bb345ca543.png",
                "PlayerSpells": {
                    "BountySpell": {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": -2,
                        "ShowSpell": false,
                        "Status": 1
                    },
                    "EarnedSpells": [
                        {
                            "UseSpellAgainst": [

                            ],
                            "SpellVariant": 6,
                            "ShowSpell": true,
                            "Status": 3
                        }
                    ]
                }
            },
            {
                "IdPlayer": 1042,
                "Affected": true,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "piksi",
                "Chips": 481,
                "AvatarURL": "Upload/b4578d909f3e4dc0816f46d7a598a475.png",
                "PlayerSpells": {
                    "BountySpell": {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": -2,
                        "ShowSpell": false,
                        "Status": 1
                    },
                    "EarnedSpells": [
                        {
                            "UseSpellAgainst": [

                            ],
                            "SpellVariant": 1,
                            "ShowSpell": true,
                            "Status": 2
                        }
                    ]
                }
            }
        ],
        "Chips": 499,
        "Value": 0
    },
    "Response": "BountySpellExecuted"
}

export const Resurrection = {
    "BountySpellExecuted": {
        "Spell": 3,
        "InitiatorPlayer": {
            "IdPlayer": 1033,
            "Affected": false,
            "InvolvedInSpellExecution": false,
            "UsedImmunity": false,
            "Name": "binic",
            "Chips": 890,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": -1,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 3,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": [

        ],
        "Chips": 0,
        "Value": 0
    },
    "Response": "BountySpellExecuted"
}

export const ScanSpells = {
    "BountySpellExecuted": {
        "Spell": 7,
        "InitiatorPlayer": {
            "IdPlayer": 1042,
            "Affected": false,
            "UsedImmunity": false,
            "Name": "piksi",
            "Chips": 990,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": 7,
                    "ShowSpell": true,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 7,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 34,
                "Affected": true,
                "UsedImmunity": false,
                "Name": "prosinecki",
                "Chips": 0,
                "AvatarURL": "1am1-0062.png",
                "PlayerSpells": {
                    "BountySpell": {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 7,
                        "ShowSpell": true,
                        "Status": 4
                    },
                    "EarnedSpells": [

                    ]
                }
            },
            {
                "IdPlayer": 1042,
                "Affected": true,
                "UsedImmunity": false,
                "Name": "piksi",
                "Chips": 990,
                "AvatarURL": "Upload/b4578d909f3e4dc0816f46d7a598a475.png",
                "PlayerSpells": {
                    "BountySpell": {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 7,
                        "ShowSpell": true,
                        "Status": 1
                    },
                    "EarnedSpells": [
                        {
                            "UseSpellAgainst": [

                            ],
                            "SpellVariant": 7,
                            "ShowSpell": true,
                            "Status": 3
                        }
                    ]
                }
            }
        ],
        "Chips": 0,
        "Value": 0
    },
    "Response": "BountySpellExecuted"
}




export const SkipBlindsOnce = {
    "BountySpellExecuted": {
        "Spell": 4,
        "InitiatorPlayer": {
            "IdPlayer": 1033,
            "Affected": false,
            "InvolvedInSpellExecution": false,
            "UsedImmunity": false,
            "Name": "binic",
            "Chips": 1130,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [

                        ],
                        "SpellVariant": 4,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": null,
        "Chips": 0,
        "Value": 12,
        "Text": "Player #1033 binic (T211838) used Skip Blinds Spell. He skipped BidSmallBlind"
    },
    "Response": "BountySpellExecuted"
}

export const RotateSeats = {
    "BountySpellExecuted": {
        "Spell": 5,
        "InitiatorPlayer": {
            "IdPlayer": 34,
            "Affected": false,
            "UsedImmunity": false,
            "Name": "prosinecki",
            "Chips": 1090,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [
                            1042
                        ],
                        "SpellVariant": 5,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 1042,
                "Affected": true,
                "UsedImmunity": false,
                "Name": "piksi",
                "Chips": 460,
                "AvatarURL": "Upload/1f7cec31a6b44ec2ab3a75bb345ca543.png",
                "PlayerSpells": null
            }
        ],
        "Chips": 0,
        "Value": 0
    },
    "Response": "BountySpellExecuted"
}


export const RotateSeatsImmunity = {
    "BountySpellExecuted": {
        "Spell": 5,
        "InitiatorPlayer": {
            "IdPlayer": 34,
            "Affected": false,
            "UsedImmunity": false,
            "Name": "prosinecki",
            "Chips": 1090,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [

                    ],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [
                            1042
                        ],
                        "SpellVariant": 5,
                        "ShowSpell": true,
                        "Status": 3
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 1042,
                "Affected": true,
                "UsedImmunity": true,
                "Name": "piksi",
                "Chips": 460,
                "AvatarURL": "Upload/1f7cec31a6b44ec2ab3a75bb345ca543.png",
                "PlayerSpells": null
            }
        ],
        "Chips": 0,
        "Value": 0
    },
    "Response": "BountySpellExecuted"
}

export const RobAPlayerMock = {
    "Response": "BountySpellExecuted",
    "BountySpellExecuted": {
        "Spell": 2,
        "InitiatorPlayer": {
            "IdPlayer": 43751,
            "Affected": false,
            "InvolvedInSpellExecution": false,
            "UsedImmunity": false,
            "Name": "tester_1500",
            "Chips": 3000,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [],
                        "SpellVariant": 2,
                        "ShowSpell": true,
                        "Status": 3
                    },
                    {
                        "UseSpellAgainst": [],
                        "SpellVariant": 4,
                        "ShowSpell": true,
                        "Status": 2
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 43735,
                "Affected": true,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "ccctester_1484",
                "Chips": 2500,
                "AvatarURL": "Upload/7ac1a813ce804c23949534d6002cf44e.png",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43750,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": true,
                "Name": "tester_1499",
                "Chips": 1015,
                "AvatarURL": "Upload/24b7ecbe599f40a09ba35e4f81d34cfd.png",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43740,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": true,
                "Name": "tester_1489",
                "Chips": 1010,
                "AvatarURL": "",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43733,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "tester_1482",
                "Chips": 1000,
                "AvatarURL": "",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43748,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "tester_1497",
                "Chips": 1000,
                "AvatarURL": "",
                "PlayerSpells": null
            }
        ],
        "Chips": 1000, // Stolen chips
        "Value": 0,
        "Text": null
    }
}

export const RobAPlayerImmunityMock = {
    "Response": "BountySpellExecuted",
    "BountySpellExecuted": {
        "Spell": 2,
        "InitiatorPlayer": {
            "IdPlayer": 43751,
            "Affected": false,
            "InvolvedInSpellExecution": false,
            "UsedImmunity": false,
            "Name": "tester_1500",
            "Chips": 3567,
            "AvatarURL": "Upload/64bdd3b75cd44969bbff30d232a046c4.png",
            "PlayerSpells": {
                "BountySpell": {
                    "UseSpellAgainst": [],
                    "SpellVariant": -2,
                    "ShowSpell": false,
                    "Status": 1
                },
                "EarnedSpells": [
                    {
                        "UseSpellAgainst": [],
                        "SpellVariant": 2,
                        "ShowSpell": true,
                        "Status": 3
                    },
                    {
                        "UseSpellAgainst": [],
                        "SpellVariant": 4,
                        "ShowSpell": true,
                        "Status": 2
                    }
                ]
            }
        },
        "AffectedPlayers": [
            {
                "IdPlayer": 43735,
                "Affected": true,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": true,
                "Name": "ccctester_1484",
                "Chips": 22508,
                "AvatarURL": "Upload/7ac1a813ce804c23949534d6002cf44e.png",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43750,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": true,
                "Name": "tester_1499",
                "Chips": 1015,
                "AvatarURL": "Upload/24b7ecbe599f40a09ba35e4f81d34cfd.png",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43740,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "tester_1489",
                "Chips": 1010,
                "AvatarURL": "",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43733,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "tester_1482",
                "Chips": 1000,
                "AvatarURL": "",
                "PlayerSpells": null
            },
            {
                "IdPlayer": 43748,
                "Affected": false,
                "InvolvedInSpellExecution": true,
                "UsedImmunity": false,
                "Name": "tester_1497",
                "Chips": 1000,
                "AvatarURL": "",
                "PlayerSpells": null
            }
        ],
        "Chips": 507,
        "Value": 0,
        "Text": null
    }
}