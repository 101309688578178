import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyInfo } from '../models/currency-info';

@Pipe({ name: 'curr' })
export class CurrencyPipe implements PipeTransform {
    transform(value: number, currency: CurrencyInfo, asObject?: boolean): any {
        let retVal = value.toLocaleString('en-US', { minimumFractionDigits: currency ? currency.NbDecimalVisible : 0, maximumFractionDigits: currency ? currency.NbDecimalVisible : 0 });

        if (asObject) {
            return {
                total: retVal,
                withoutDecimal: retVal.split('.')[0] ? `${retVal.split('.')[0]}` : undefined,
                decimal: retVal.split('.')[1] ? `${retVal.split('.')[1]}` : undefined,
                symbol: currency ? currency.Symbol : ''
            }
        }
        if (!currency || !currency.Symbol) { return retVal; }

        if (currency.SymbolAfter) {
            retVal = `${retVal} ${currency ? currency.Symbol : ''}`
        } else {
            retVal = currency.Symbol + retVal;
        }
        return retVal;
    }
}
