import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BountySpellVariant, SpellStatus } from '../../../../shared/models/bounty-spell';

@Component({
  selector: 'app-dialog-player-spells',
  templateUrl: './dialog-player-spells.component.html',
  styleUrls: ['./dialog-player-spells.component.scss']
})
export class DialogPlayerSpellsComponent implements OnInit {
  bountySpellVariant = BountySpellVariant
  spellStatus = SpellStatus
  constructor(
    public dialogRef: MatDialogRef<DialogPlayerSpellsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { spells: any[], playerName: string, isMyPlayer?: boolean }
  ) {
    console.log(data)
    data.spells = data.spells
      .map(spell => {
        spell.sortIndex = 0;
        switch (spell.Status) {
          case SpellStatus.NotActivated:
            spell.sortIndex = 1;
            break;
          case SpellStatus.Activated:
            spell.sortIndex = 2;
            break;
          case SpellStatus.Used:
            spell.sortIndex = 5;
            break;
          case SpellStatus.TakenAway:
            spell.sortIndex = 7;
            break;
          case SpellStatus.Wasted:
            spell.sortIndex = 6;
            break;
          case SpellStatus.UsedBigBlind:
            spell.sortIndex = 3;
            break;
          case SpellStatus.UsedSmallBlind:
            spell.sortIndex = 4;
            break;
          case SpellStatus.Disabled:
            spell.sortIndex = 8;
            break;
          default:
            break;
        }
        return spell
      }).sort((a, b) => {
        return a.sortIndex > b.sortIndex ? 1 : -1
      })

  }

  ngOnInit(): void {
  }
  useSpell(spell) {
    if(this.data.isMyPlayer) {
      this.dialogRef.close({ spell })
    }
  }
}
